import WcFormsAlerts from '@components/shared/forms/WcFormsAlerts'
import { notify } from '@common/notifications/dispatch'

export const Panel = {
  mixins: [notify],

  components: {
    WcFormsAlerts,
  },

  computed: {
    panelShow() {
      return !this.isLoading && this.panelData
    },
    panelData() {
      return Object.keys(this.panel.data).length > 0
    },
    panelShowErrors() {
      return (
        !this.skipPanelShowErrors && !this.panelShow && this.$getDeep(this.panel, 'errors.base')
      )
    },
    cachePayloadInit() {
      return this.cachePayload || this.cachePayloadFunc()
    },
    apiParams() {
      return {
        get: [{}],
      }
    },
  },

  methods: {
    panelFill() {
      if (this.skipPanelFill) return
      if (this.$route.query.refresh) {
        this.panelReload({ skipPanelFill: true })
        return this.$router.replace({ query: delete { ...this.$route.query }.refresh })
      }
      this.isLoading = !this.$store.cache.has(this.cacheType, this.cachePayloadInit)
      this.panel.errors = {}
      this.cacheRequest = this.$store.cache.dispatch(
        this.cacheType,
        this.cachePayloadInit,
        this.cacheOptions
      )
      return this.cacheRequest.then((response) => {
        this.apiRequest = response
        this.apiRequest.promise // eslint-disable-line promise/no-nesting
          .then((response) => {
            this.panel = { data: {}, rs: {} }
            this.$mergeDeep(this.panel, this.panelResponse(response))
            if (!this.skipNotifications) this.notifyDispatch(this.notices)
            this.isLoading = false
            this.apiCallback('fill-success', response)
            return true
          })
          .catch((response) => {
            this.panelRequestErrors(this.panelResponse(response))
            if (!this.skipNotifications) this.notifyDispatch(this.notices)
            this.isLoading = false
            this.apiCallback('fill-error', response)
          })
        this.apiCallback('fill')
        return true
      })
    },
    panelRequestErrors(response) {
      if (response.errors) {
        /* Find first error with source attribute. */
        const sources = response.errors.find((obj) => {
          return obj.source && obj.source.length !== 0
        })
        this.$setDeep(this.panel, 'errors.source', sources ? sources.source : {})
        /* Find all errors with a title or detail. */
        const errors = response.errors.filter((obj) => {
          return obj.title || obj.detail
        })
        this.$setDeep(this.panel, 'errors.base', errors)
      }
    },
    panelReload(options = {}) {
      this.$store.cache.clear(this.cacheType, this.cachePayloadInit)
      if (!options.skipPanelFill) this.panelFill()
    },
    panelDataIncluded(type, id) {
      if (this.panel.included) {
        return this.panel.included.find((v) => v.type === type && v.id === id)
      }
    },
    panelDataIncludedMany(data, type, id) {
      if (!data || !this.panel.included) return []
      return data.reduce((acc, rv) => {
        acc.push(this.panel.included.find((v) => v.type === rv[type] && v.id === rv[id]))
        return acc
      }, [])
    },
    panelRequest(action = this.action, paction = null) {
      return this.apiBase[action](this.$http, ...this.apiParams[paction ? paction : action])
    },
    panelResponse(response) {
      this.notices = { meta: { notices: this.$getDeep(response, 'meta.notices') } }
      this.$deleteDeep(response, 'meta.notices')
      return response
    },
    cachePayloadFunc() {
      return null
    },
    apiCallback() {},
  },

  data() {
    return {
      isLoading: true,
      cacheType: null,
      cachePayload: null,
      cacheOptions: {},
      cacheRequest: null,
      apiBase: null,
      apiRequest: null,
      skipPanelShowErrors: false,
      skipNotifications: false,
      skipPanelFill: false,
      panel: {
        data: {},
        rs: {},
      },
      notices: null,
    }
  },

  watch: {
    $route: 'panelFill',
  },

  mounted() {
    this.panelFill()
  },
}
