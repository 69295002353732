<template lang="pug">
  b-nav-item-dropdown.lm-header-account(
    toggle-class='d-flex align-items-center pr-0'
    menu-class='position-absolute rounded-0 border-0 m-0 p-0'
    no-caret
    right)
    template(slot='button-content')
      .d-flex.align-items-center.dropdown-toggle-rounded.my-2.p-lg-2(v-if='panelShow')
        span.small.text-body.mx-3
          | {{ $t('account.hello') }},
          span.font-weight-bold
            |  {{ $getDeep(panel.rs, 'profile.attributes.firstname') || $getDeep(panel.rs, 'identity.attributes.username') || $t('account.no_name') }}
        b-avatar.wc-bubble.wc-bubble-xxs.wc-bubble-lg-xs.wc-bubble-bl.shadow-xs(
          :src='panel.data.attributes.portrait_xsmall_url'
          variant='white'
          alt='')
          template(v-if='!panel.data.attributes.portrait_xsmall_url' #default)
            .wc-bubble-content.wc-bubble-linked.bg-primary
              .i-wrapper
                i.fal.fa-fw.fa-lg-15x.fa-user.text-white
      .d-flex.align-items-center.dropdown-toggle-rounded.my-2.p-lg-2(v-else)
        span.small.text-body.mx-3
          | {{ $t('account.hello') }}, {{ $t('account.no_name') }}
        b-avatar.wc-bubble.wc-bubble-xxs.wc-bubble-lg-xs.wc-bubble-bl.shadow-xs(
          variant='white'
          alt='')
          template(#default)
            .wc-bubble-content.wc-bubble-linked.bg-primary
              .i-wrapper
                i.fal.fa-fw.fa-lg-15x.fa-user.text-white
    template(v-if='panelShow')
      b-dropdown-item(
        link-class='py-3'
        :to="{ name: 'manager-account-edit' }"
        :active="exact('/manager/account')")
        i.fal.fa-btn.fa-fw.fa-key
        |  {{ $t('account.menus.my_account') }}
      b-dropdown-item(
        link-class='py-3'
        :to="{ name: 'manager-account-profile-edit' }"
        :active="starts('/manager/account/profile')")
        i.fal.fa-btn.fa-fw.fa-user
        |  {{ $t('account/profile.menus.my_profile') }}
      b-dropdown-item(
        link-class='py-3'
        :to="{ name: 'manager-account-contact-edit' }"
        :active="starts('/manager/account/contact')")
        i.fal.fa-btn.fa-fw.fa-comment-dots
        |  {{ $t('contact.menus.my_contact') }}
      b-dropdown-item(
        link-class='py-3'
        :to="{ name: 'manager-account-address-edit' }"
        :active="starts('/manager/account/address')")
        i.fal.fa-btn.fa-fw.fa-map-marker
        |  {{ $t('address.menus.my_address') }}
      b-dropdown-item(
        link-class='py-3'
        @click.stop.prevent='onLogout')
        i.fal.fa-btn.fa-fw.fa-unlock
        |  {{ $t('session.sign_out') }}
</template>

<script>
import { Panel } from '@common/panel'
import { Menu } from '@common/menu'

export default {
  name: 'lm-header-account',
  mixins: [Panel, Menu],
  methods: {
    onLogout() {
      this.$auth.signOut({
        extraQueryParams: { ui_locales: this.$wc.shared.locale },
      })
    },
    cachePayloadFunc() {
      return { account_id: this.$store.getters['auth/currentAccount'].sub }
    },
  },
  watch: {
    panel() {
      this.panel.rs.profile = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.profile.data.type'),
        this.$getDeep(this.panel.data, 'relationships.profile.data.id')
      )
      this.panel.rs.identity = this.panelDataIncluded(
        this.$getDeep(this.panel.data, 'relationships.identity.data.type'),
        this.$getDeep(this.panel.data, 'relationships.identity.data.id')
      )
    },
  },
  data() {
    return {
      cacheType: 'account/getPanel',
      cacheOptions: { timeout: this.$wc.conf.cache.session },
      skipNotifications: true,
    }
  },
}
</script>
