<template lang="pug">
  .lm-header-config-global
    b-dropdown-text.h5.pt-3
      | {{ $t('shared.menus.globals') }}

    b-dropdown-item(
      link-class='text-body py-3'
      :to="{ name: 'manager-categories' }"
      :active="starts('/manager/categories')")
      i.fal.fa-btn.fa-fw.fa-layer-group.text-muted
      |  {{ $t('activerecord.models.category.other') }}
    b-dropdown-item(
      link-class='text-body py-3'
      :to="{ name: 'manager-genders' }"
      :active="starts('/manager/genders')")
      i.fal.fa-btn.fa-fw.fa-venus-mars.text-muted
      |  {{ $t('activerecord.models.gender.other') }}
</template>

<script>
import { Menu } from '@common/menu'

export default {
  name: 'lm-header-config-global',
  mixins: [Menu],
}
</script>
