<template lang="pug">
  header#header.lm-header
    b-navbar#mainbar.p-0(ref='headerMainbar' toggleable='lg' type='light' variant='primary')
      b-navbar-brand#mainbar-brand.d-lg-none.d-inline-flex.align-items-center.m-0.p-0.pl-3.mr-auto(:to="{ name: 'dashboard' }")
        figure.figure.brand.d-flex.mb-0
          .wc-i.wc-symbol.rounded
        .wc-manager.text-uppercase.text-white.ml-2
          | {{ $t('shared.menus.manager') }}

      aside-menu-toggle.d-lg-none

      b-navbar-toggle.mainbar-toggle.nav-link.shadow-none.px-0.pr-3(target='main-tools-collapse')
        i.fal.fa-btn.fa-fw.fa-25w.fa-ellipsis-v.text-white

      b-collapse#main-tools-collapse.px-3(is-nav)
        b-navbar-nav#main-tools.navbar-item-btn.ml-auto.d-flex.justify-content-end.flex-row

          <!-- Notifications -->
          b-nav-item(link-classes='d-flex align-items-center px-2 px-lg-3')
            i.fal.fa-btn.fa-fw.fa-25w.fa-bell.text-white

          <!-- Settings -->
          b-nav-item-dropdown(
            v-if='isAdmin'
            toggle-class='d-flex align-items-center px-2 px-lg-3'
            menu-class='position-absolute rounded-0 border-0 m-0 p-0'
            no-caret
            right)
            template(slot='button-content')
              i.fal.fa-btn.fa-fw.fa-25w.fa-cog.text-white
            .d-md-flex.align-items-start.justify-content-start
              header-config-global
              header-config-others

          <!-- Account -->
          header-account
</template>

<script>
import AsideMenuToggle from '@components/layouts/manager/aside/MenuToggle'
import HeaderAccount from '@components/layouts/manager/header/Account'
import HeaderConfigGlobal from '@components/layouts/manager/header/ConfigGlobal'
import HeaderConfigOthers from '@components/layouts/manager/header/ConfigOthers'

export default {
  name: 'lm-header',
  components: {
    AsideMenuToggle,
    HeaderAccount,
    HeaderConfigGlobal,
    HeaderConfigOthers,
  },
  computed: {
    isAdmin() {
      /* This $root is used because of a bug on server side. */
      return this.$root.$store.getters['auth/isAdmin']
    },
  },
}
</script>

<style lang="scss">
.lm-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 95;
}
</style>
