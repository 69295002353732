<template lang="pug">
  .lm-header-config-others
    b-dropdown-text.h5.pt-3
      | {{ $t('activerecord.models.contact.other') }}

    b-dropdown-item(
      link-class='text-body py-3'
      :to="{ name: 'manager-contacts-link_networks' }"
      :active="starts('/manager/contacts/link_networks')")
      i.fal.fa-btn.fa-fw.fa-share-alt.text-muted
      |  {{ $t('activerecord.models.contact/link_network.other') }}

    b-dropdown-text.h5.pt-3
      | {{ $t('activerecord.models.machine.other') }}

    b-dropdown-item(
      link-class='text-body py-3'
      :to="{ name: 'manager-machines-models' }"
      :active="starts('/manager/machines/models')")
      i.fal.fa-btn.fa-fw.fa-tags.text-muted
      |  {{ $t('activerecord.models.machine/model.other') }}
</template>

<script>
import { Menu } from '@common/menu'

export default {
  name: 'lm-header-config-others',
  mixins: [Menu],
}
</script>
