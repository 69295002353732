<template lang="pug">
  #wrapper.l-manager.l-wrapper(:class='wrapperClass')
    #wrapper-inner.l-wrapper-inner
      #content.l-content
        manager-header
        slot
        manager-footer
      manager-aside
</template>

<script>
import ManagerHeader from '@components/layouts/manager/Header'
import ManagerFooter from '@components/layouts/manager/Footer'
import ManagerAside from '@components/layouts/manager/Aside'

export default {
  name: 'l-manager',
  components: {
    ManagerHeader,
    ManagerFooter,
    ManagerAside,
  },
  computed: {
    wrapperClass() {
      return this.$store.getters['ui/isAsideOpen'] ? 'l-aside-open' : 'l-aside-closed'
    },
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.l-manager {
  #wrapper-inner {
    padding-left: $wc-sidebar-width;
    transition: $wc-transition-all-cubic;
    transform-origin: center left;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    &.l-aside-closed #wrapper-inner {
      padding-left: $wc-sidebar-min-width;
    }
  }
  .l-content {
    background-color: $wc-bg;
    padding-top: $wc-mainbar-height;
    @include media-breakpoint-down(md) {
      padding-top: $wc-mainbar-height-mobile;
    }
  }
  .l-section {
    > .l-section-h {
      padding-top: 1.5rem;
    }
    &:last-child > .l-section-h:last-child {
      padding-bottom: 1.5rem;
    }
  }
}
</style>
