<template lang="pug">
  aside#aside.lm-aside
    b-navbar#sidebar.flex-column.align-items-stretch.p-0(type='light' variant='white')

      #sidebar-header.d-none.d-lg-block
        .d-flex.justify-content-between.align-items-center.bg-primarydark.px-4
          b-navbar-brand#sidebar-brand.d-inline-flex.align-items-center.m-0.p-0(
            :to="{ name: 'dashboard' }")
            figure.figure.brand.d-flex.mb-0
              .wc-i.wc-symbol.rounded
            .wc-manager.text-uppercase.text-white.ml-2
              | {{ $t('shared.menus.manager') }}

          aside-menu-toggle

      #sidebar-menu.flex-grow-1
        aside-menu

      #sidebar-footer
</template>

<script>
import AsideMenuToggle from '@components/layouts/manager/aside/MenuToggle'
import AsideMenu from '@components/layouts/manager/aside/Menu'

export default {
  name: 'lm-aside',
  components: {
    AsideMenuToggle,
    AsideMenu,
  },
}
</script>

<style lang="scss">
@import '@assets/css/plugins/bootstrap/config';

.l-manager {
  #aside,
  #sidebar {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: $wc-sidebar-height;
    @include media-breakpoint-down(md) {
      min-height: $wc-sidebar-height-mobile;
    }
  }
  #aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: $wc-sidebar-width;
    width: $wc-sidebar-width;
    margin-left: -$wc-sidebar-width;
    box-shadow: 0 0 1.5rem rgba($wc-black, 0.25);
    transition: $wc-transition-all-cubic;
    transform-origin: center left;
    z-index: 1000;
    #sidebar-menu {
      a {
        cursor: pointer;
      }
    }
    #sidebar-brand .wc-i {
      transition: $wc-transition-all-ease;
    }
    #sidebar-footer,
    #sidebar-footer > div > * {
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &.l-aside-closed #sidebar-brand .wc-manager {
    display: none;
  }
  @include media-breakpoint-up(lg) {
    &.l-aside-closed #aside:hover {
      width: $wc-sidebar-width;
    }
    &.l-aside-closed #aside:not(:hover) {
      width: $wc-sidebar-min-width;
      #sidebar-brand .wc-i,
      #sidebar-footer {
        width: 0;
      }
    }
  }
  @include media-breakpoint-down(md) {
    #aside {
      top: $wc-mainbar-height-mobile;
      #sidebar-footer {
        margin-bottom: $wc-footer-min-height;
      }
    }
    &.l-aside-closed #aside {
      width: 0;
    }
  }
}
</style>
