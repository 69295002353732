<template lang="pug">
  b-button.lm-aside-menu-toggle.shadow-none.nav-link.px-0(
    variant='transparent'
    @click.stop.prevent='handleClick')
    i.fal.fa-btn.fa-25w.text-white(:class='iconClass')
</template>

<script>
export default {
  name: 'lm-aside-menu-toggle',
  computed: {
    iconClass() {
      /* This $root is used because of a bug on server side. */
      return this.$root.$store.getters['ui/isAsideOpen'] ? 'fa-align-right' : 'fa-align-left'
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('ui/toggleAside')
    },
  },
}
</script>

<style lang="scss">
.lm-aside-menu-toggle {
  &:hover i:before {
    content: '\f039';
  }
}
</style>
